#editor,
.editor {
  background: white;
  color: black;
  background-clip: padding-box;
  border-radius: 0;
  padding: 0px 0;
  font-family: Inter, sans-serif;
  font-size: 14px;
  min-height: calc(100vh - 270px);
}

#editor s,
.editor s {
  text-decoration-color: #6f7c9f;
}

#editor p,
.editor p {
  word-break: break-word;
}

#editor > * > *,
.editor > * > * {
  margin-top: 0px;
  margin-bottom: 0px;
}

.ProseMirror-focused {
  outline: none;
}

.ProseMirror {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 300px;
  margin: 14px 0 0 0;
}

.ProseMirror-content {
  overflow: auto;
}
/* LISTS */

li {
  margin-top: 10px;
}

li:last-of-type {
  margin-bottom: 10px;
}

li p {
  margin-top: 0px;
  margin-bottom: 0px;
}

/* TO DO LISTS */
ul[data-type="todo_list"] {
  padding-left: 16px;
}

li[data-type="todo_item"] {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

li[data-type="todo_item"]:last-of-type {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}

.todo-checkbox {
  box-sizing: border-box;
  border: 2px solid #bfbfbf;
  height: 20px;
  width: 20px;
  margin-right: 14px;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  border-radius: 0.3em;
}

.todo-checkbox-checked {
  background-color: #2fdba1;
  border-color: #2fdba1;
  background-image: url("tick.svg");
  background-repeat: no-repeat;
  background-size: 65%;
  background-position: center;
}

.todo-content {
  flex: 1;
}

.todo-content p {
  margin: 0;
}

li[data-done="true"] {
  text-decoration: line-through;
  text-decoration-color: #6f7c9f;
}

li[data-done="true"] .todo-checkbox-unchecked {
  display: none;
}

li[data-done="false"] .todo-checkbox-checked {
  display: none;
}

li[data-done="true"] li[data-done="false"] .todo-checkbox-checked {
  display: inline-block;
}

li[data-done="false"] li[data-done="true"] .todo-checkbox-checked {
  display: inline-block;
}

li[data-done="false"] {
  text-decoration: none;
}
/* TABLE */
.ProseMirror table {
  margin: 0;
  /* border-collapse: collapse; breaks border-radius */
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  overflow: hidden;
  border: 1px solid #e7e9ef;
  border-radius: 6px;
}

.ProseMirror table tbody tr:first-child td:first-child {
  border-top-left-radius: 6px;
}

.ProseMirror table tbody tr:first-child td:last-child {
  border-top-right-radius: 6px;
}

.ProseMirror table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

.ProseMirror table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}

.ProseMirror .tableWrapper {
  margin: 1em 0;
}

/* TABLE ELEMENTS */
.ProseMirror th,
.ProseMirror td {
  min-width: 1em;
  border: 1px solid #e7e9ef;
  text-align: left;

  vertical-align: top;
  box-sizing: border-box;
  position: relative;

  overflow: auto;
}

/* FIX TABLE INNER PADDING */
.ProseMirror table > tbody > tr > th > p {
  margin: 0.25em 0 0.25em 0;
}

.ProseMirror table > tbody > tr > td > p {
  margin: 0.25em 0 0.25em 0;
}

/* FIX TABLE IMAGES */
.ProseMirror table > tbody > tr > td > p > img {
  max-width: 100%;
}

.ProseMirror table > tbody > tr > th > p > img {
  max-width: 100%;
}

/* FIX TABLE TABLES */
/* .ProseMirror table > tbody > tr > td > div.tableWrapper {
  width: 100% !important;
  margin: 0;
}

.ProseMirror table > tbody > tr > td > div.tableWrapper > table {
  width: 100% !important;
  overflow: hidden !important;
} */

.ProseMirror th {
  font-weight: bold;
  text-align: left;
}

.ProseMirror .column-resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 2px;
  z-index: 20;
  background-color: #058fef;
  opacity: 0.5;
  pointer-events: none;
  box-shadow: 0 9px 0px 0px white, 0 -9px 0px 0px white,
    12px 0 15px -4px rgba(22, 151, 240, 0.8),
    -12px 0 15px -4px rgba(22, 151, 240, 0.8);
}

.ProseMirror.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.ProseMirror .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(22, 151, 240, 0.1);
  pointer-events: none;
}

/* .emoji-suggesting {
  color: orange;
  padding: 1px;
  background-color: rgba(255, 166, 0, 0.075);
  border-radius: 4px;
  overflow: hide;
} */

.mentions-suggesting {
  color: #058fef;
}

.resolved-mention {
  color: blue;
  padding: 1px;
  background-color: rgba(0, 0, 255, 0.096);
  border-radius: 4px;
  overflow: hide;
}

.mention-no-color {
  padding: 1px;
  border-radius: 4px;
  overflow: hide;
}

/* FOR DEVELOPMENT */
/* .resolved-mention[promote-chance="true"] {
  background-color: rgba(255, 0, 255, 0.096) !important;
} */

.resolved-mention::before {
  content: "@";
}

/* .command-suggesting {
  color: blueviolet;
  padding: 1px;
  background-color: rgba(137, 43, 226, 0.199);
  border-radius: 4px;
  overflow: hide;
} */

ol ol {
  list-style-type: lower-alpha;
}

ol ol ol {
  list-style-type: lower-roman;
}

/* READONLY STYLES */

.readonly-grey {
  filter: blur(1px);
}

.ProseMirror a,
a:link,
a:visited,
a:hover,
a:active {
  background-color: transparent;
  cursor: pointer;
  /* blue6 */
  color: #058fef;
  text-decoration: underline;
  /* blue6 */
  text-decoration-color: "#058FEF";
}

/* WIDGET STYLES */
/* keep the widget relative to the document, and the content inside it absolute. */
/* make sure it doesn't take up any visible space in the DOM */
.ProseMirror .link-preview-widget {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  cursor: default;
  max-width: 0;
  max-height: 0;
}

/* DECORATION STYLES */
.ProseMirror .link_preview {
  background-color: transparent;
  cursor: pointer;
  color: #058fef;
  text-decoration: underline;
  text-decoration-color: "#058FEF";
}
/* Not used, but kept for future reference
.ProseMirror .link_preview::before {
  background-color: transparent;
  cursor: pointer;
  color: #058FEF;
  text-decoration: underline;
  text-decoration-color: #058FEF;
  content: "" attr(title) "";
}

.ProseMirror .link_preview::after {
  background-color: transparent;
  cursor: pointer;
  color: #058FEF;
  text-decoration: underline;
  text-decoration-color: #058FEF;
  vertical-align: bottom;
  font-size: 0.8em;
  content: " [" attr(href) "]";
} */

.locked-prosemirror-task {
  pointer-events: none;
  opacity: 0.6;
}

#editor .task-wrapper[data-taskid] {
  display: block;
  padding: 10px;
  background: #ffffff;

  /* Dark Blue/darkBlue1 */
  border: 1px solid #e7e9ef;
  box-sizing: border-box;
  border-radius: 8px;
  gap: 8px;
  line-height: 1.4;
}

#editor .task-wrapper:hover {
  background-color: #fafafa;
}

#editor .task-wrapper[data-taskid=""] {
  display: block;
  padding: 10px;
  background: #fffae9;

  /* Dark Blue/darkBlue1 */
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  gap: 8px;
  line-height: 1.4;
}

#editor .task-wrapper[data-taskid=""]:hover {
  background-color: #fff6d9;
}

#editor .task-wrapper[data-taskid=""] .pm-task-calendar {
  display: none;
}



/* #editor .task-wrapper input[type="checkbox"]:checked {
  background-color: #2FDBA1;
  border-color: #2FDBA1;
  background-image: url("tick.svg");
  background-repeat: no-repeat;
  background-size: 65%;
  background-position: center;
} */

#editor .task-wrapper span.pm-task-user {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  cursor: pointer;

  /* identical to box height, or 143% */

  /* Cyan/cyan7 */
  /* color: #51b2cb; */

  /* background-color: #f0fcff; */
  margin-left: 26px;
  margin-right: 4px;
  border-radius: 8px;
  padding: 0 8px;
  z-index: 1;

  position: absolute;
}

#editor .task-wrapper span.pm-task-user:before {
  content: "@";
}

#editor .task-wrapper span.pm-task-due-date {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  cursor: pointer;

  /* identical to box height, or 143% */

  border-radius: 8px;
  padding: 0 8px;
  /* margin-left: 4px; */
  margin-right: 8px;
}

#editor .task-wrapper span.pm-task-calendar {
  font-size: 14px;
  line-height: 20px;

  cursor: pointer;

  /* identical to box height, or 143% */
  border-radius: 8px;
}

#editor .task-wrapper span.pm-task-calendar svg {
  margin-bottom: -3px;
  fill: #b7becf;
}

#editor .task-wrapper span.pm-task-calendar svg:hover {
  fill: #6f7c9f;
}

#editor .task-wrapper span.task-checkbox {
  background: #ffffff;

  /* Gray/gray6 */
  display: inline-block;
  border: 2px solid #bfbfbf;
  box-sizing: border-box;
  border-radius: 4px;
  max-width: 20px;
  max-height: 20px;
  width: 20px;
  height: 20px;
  margin: 0;
  cursor: pointer;
  width: 100%;
  padding: 0 8px 0 8px;
  margin-right: 4px;
  margin-bottom: -5px;

  position: absolute;
}

#editor .task-wrapper[data-status="inProgress"] span.task-checkbox {
  background-image: url("in-progress.svg");
  background-size: cover;
  background-position: center;
  border-color: #058fef;
  background-color: #058fef;
}

#editor .task-wrapper[data-status="completed"] span.task-checkbox {
  background-color: #2fdba1;
  border-color: #2fdba1;
  background-image: url("tick.svg");
  background-repeat: no-repeat;
  background-size: 65%;
  background-position: center;
}

#editor .task-wrapper span {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  /* identical to box height, or 143% */

  /* Layout/surface */
  color: #1b2124;
}

#editor .task-wrapper .pm-task-name {
  /* margin-left: 4px; */
  margin-right: 6px;
  word-break: break-word;
  display: inline-block;
  line-height: 22px;
}


#editor .task-wrapper .pm-task-name:empty::after {
  white-space: normal;
  content: attr(placeholder);
  color: #b7becf;
  pointer-events: none;
  display: inline-block;
  caret-color: black;
  text-indent: 0;
}

#editor .task-wrapper[data-taskid=""] .pm-task-name:empty::after {
  white-space: normal;
  content: attr(placeholder);
  color: #f2cb4b;
  pointer-events: none;
  display: inline-block;
  caret-color: black;
  text-indent: 0;
}

#editor .task-wrapper .pm-enter-message {
  display: none;
}

#editor .task-wrapper[data-taskid=""] .pm-enter-message {
  display: inline-block;
  font-size: 12px;
  /* margin-left: 4px; */
  color: #d3a100;
  pointer-events: none;
  user-select: none;
}

#editor .task-wrapper[data-taskid=""] .pm-enter-message::before {
  display: inline-block;
  font-size: 12px;
  /* margin-left: 4px; */
  color: #d3a100;
  pointer-events: none;
  user-select: none;
  content: 'Press enter to create';
}


/* #editor .task-wrapper .pm-task-name:empty:focus::before {
  content: "";
  border-left: 1px solid #058fef;
} */

/* PLACEHOLDER STYLES */

.ProseMirror[data-placeholder]::before {
  color: #6f7c9f;
  position: absolute;
  content: attr(data-placeholder);
  pointer-events: none;
  animation-duration: 0.3s;
  animation-name: placeholder;
}

.ProseMirror[data-placeholder] {
  caret-color: transparent;
}

.ProseMirror[data-placeholder]:focus::after {
  content: "";
  width: 1px;
  height: 16px;
  background-color: black;
  position: absolute;
  top: 0;
  left: 16px;
  animation-name: blinkingCaret;
  animation-duration: 1060ms;
  animation-iteration-count: infinite;
}

@keyframes blinkingCaret {
  0% {
    opacity: 1;
  }
  49.99% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  99.99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes placeholder {
  from {
    color: rgba(111, 124, 159, 0);
  }

  to {
    color: rgba(111, 124, 159, 1);
  }
}

/* CODE BLOCK STYLES */

.codeblock-select {
  position: absolute;
  right: 0;
  z-index: 100;
  opacity: 0;
  transition: all 0.3s ease;
  margin: 6px 14px;
}
.codeblock-root {
  position: relative;
  border-radius: 6px !important;
  border: 1px solid #e7e9ef !important;
}

.codeblock-root:hover .codeblock-select {
  opacity: 1;
}

.cm-editor {
  border-radius: 6px !important;
}

.cm-gutters {
  border-radius: 6px 0 0 6px !important;
}

.ͼ1.cm-editor.cm-focused {
  border: 1px solid #058fef !important;
  border-radius: 6px;
  outline: none !important;
}

/* YJS CURSORS */
/* Keep these at the bottom, otherwise the badges/flags will have their styles altered by other containers. */

/* this is a rough fix for the first cursor position when the first paragraph is empty */
.ProseMirror > .ProseMirror-yjs-cursor:first-child {
  margin-top: 16px;
}
/* .ProseMirror p:first-child, .ProseMirror h1:first-child, .ProseMirror h2:first-child, .ProseMirror h3:first-child, .ProseMirror h4:first-child, .ProseMirror h5:first-child, .ProseMirror h6:first-child {
  margin-top: 16px
} */

.pyc-purple-body-cursor {
  background-color: #585dcd;
  color: whitesmoke !important;
  /* background-color: #eeeffa;
  color: #585dcd !important; */
}

.pyc-purple-body {
  background-color: #eeeffa;
  color: #585dcd !important;
}

.pyc-purple-caret {
  border-color: #585dcd;
  border-left: 1px solid #585dcd;
  border-right: 1px solid #585dcd;
}

.pyc-green-body-cursor {
  background-color: #2fdba1;
  color: whitesmoke !important;
  /* background-color: #eafbf6;
  color: #2fdba1 !important; */
}

.pyc-green-body {
  background-color: #eafbf6;
  color: #2fdba1 !important;
}

.pyc-green-caret {
  border-color: #2fdba1;
  border-left: 1px solid #2fdba1;
  border-right: 1px solid #2fdba1;
}

.pyc-cyan-body-cursor {
  background-color: #65defe;
  color: whitesmoke !important;
}

.pyc-cyan-body {
  background-color: #f0fcff;
  color: #65defe !important;
}

.pyc-cyan-caret {
  border-color: #65defe;
  border-left: 1px solid #65defe;
  border-right: 1px solid #65defe;
}

.pyc-blue-body-cursor {
  background-color: #058fef;
  color: whitesmoke !important;
}

.pyc-blue-body {
  background-color: #e6f4fd;
  color: #058fef !important;
}

.pyc-blue-caret {
  border-color: #058fef;
  border-left: 1px solid #058fef;
  border-right: 1px solid #058fef;
}

.pyc-red-body-cursor {
  background-color: #fe5968;
  color: whitesmoke !important;
}

.pyc-red-body {
  background-color: #ffeef0;
  color: #fe5968 !important;
}

.pyc-red-caret {
  border-color: #fe5968;
  border-left: 1px solid #fe5968;
  border-right: 1px solid #fe5968;
}

.pyc-orange-body-cursor {
  background-color: #ff772a;
  color: whitesmoke !important;
}

.pyc-orange-body {
  background-color: #ffe4d5;
  color: #ff772a !important;
}

.pyc-orange-caret {
  border-color: #ff772a;
  border-left: 1px solid #ff772a;
  border-right: 1px solid #ff772a;
}

.pyc-pink-body-cursor {
  background-color: #f70998;
  color: whitesmoke !important;
}

.pyc-pink-body {
  background-color: #fdceea;
  color: #f70998 !important;
}

.pyc-pink-caret {
  border-color: #f70998;
  border-left: 1px solid #f70998;
  border-right: 1px solid #f70998;
}

.pyc-darkblue-body-cursor {
  background-color: #261b9f;
  color: whitesmoke !important;
}

.pyc-darkblue-body {
  background-color: #d4d1ec;
  color: #261b9f !important;
}

.pyc-darkblue-caret {
  border-color: #261b9f;
  border-left: 1px solid #261b9f;
  border-right: 1px solid #261b9f;
}

/* Timestamp w and w/o divider */

div[timestamp] {
  color: #058fef;
  padding: 6px 8px;
  background-color: #f7f8f8;
  border: 1px solid #e7e9ef;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: 600;
  z-index: 10;
  display: inline-block;
}

div[divtimestamp] {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

div[divtimestamp]:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px solid #e7e9ef;
  background: #e7e9ef;
  width: 100%;
  transform: translateY(-50%);
}

div[divtimestamp] span {
  color: #058fef;
  padding: 6px 8px;
  background-color: #f7f8f8;
  border: 1px solid #e7e9ef;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: 600;
  z-index: 10;
}

/* Divider */

#editor hr {
  border-top: 1px solid #e7e9ef;
  background-color: #e7e9ef;
}

/* Blockquote */

#editor blockquote {
  border-left: solid 4px #058fef;
  margin-left: 0px;
  padding-left: 16px;
  font-weight: 600;
}

#editor blockquote p::before {
  content: "“";
}

#editor blockquote p::after {
  content: "”";
}

/* Link Preview Style */

.link-preview-controls svg {
  fill: #1b2124;
}

.link-preview-controls svg:hover {
  filter: invert(25%) sepia(98%) saturate(1681%) hue-rotate(186deg)
    brightness(98%) contrast(97%);
}

/* Suggester icons */

.ic-gif {
  background-image: url("imageIcon/Media/GIF.svg");
}

.ic-timestamp {
  background-image: url("imageIcon/Inline/Time Stamp.svg");
}

.ic-timestamp-divider {
  background-image: url("imageIcon/Inline/Time Stamp Divider.svg");
}

.ic-task {
  background-image: url("imageIcon/Basic Blocks/Task.svg");
}

.ic-link {
  background-image: url("imageIcon/Basic Blocks/Link.svg");
}

.ic-mentions {
  background-image: url("imageIcon/Basic Blocks/Mention.svg");
}

.ic-insert-template {
  background-image: url("imageIcon/Templates/Insert Template.svg");
}

.ic-image {
  background-image: url("imageIcon/Media/Image.svg");
}

.ic-bulleted {
  background-image: url("imageIcon/Basic Blocks/Bullet List.svg");
}

.ic-checkboxed {
  background-image: url("imageIcon/Basic Blocks/Checkbox.svg");
}

.ic-numbered {
  background-image: url("imageIcon/Basic Blocks/Numbered List.svg");
}

.ic-table {
  background-image: url("imageIcon/Basic Blocks/Table.svg");
}

.ic-save-as-template {
  background-image: url("imageIcon/Templates/Save As Template.svg");
}

.ic-colour {
  background-image: url("imageIcon/Colour/Colour.svg");
}

.ic-highlight {
  background-image: url("imageIcon/Colour/Highlight.svg");
}

.ic-divider {
  background-image: url("imageIcon/Inline/Divider.svg");
}

.ic-code {
  background-image: url("imageIcon/Inline/Code.svg");
}

.ic-quote {
  background-image: url("imageIcon/Inline/Quote.svg");
}

.ic-shortcuts {
  background-image: url("imageIcon/Inline/Shortcuts.svg");
}

.ic-emoji {
  background-image: url("imageIcon/Media/Emoji.svg");
}

/* This gives the remote user caret. The colors are automatically overwritten*/
.ProseMirror-yjs-cursor {
  position: relative;
  display: inline;
  margin-left: -1px;
  margin-right: -1px;
  white-space: nowrap;
  pointer-events: none;
}
/* This renders the username above the caret */
.ProseMirror-yjs-cursor > span {
  font-family: Inter, sans-serif;
  position: absolute;
  display: none;
  top: -1.05em;
  left: -1px;
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  user-select: none;
  padding-left: 4px;
  padding-right: 4px;
  white-space: nowrap;
  word-break: keep-all;

  border-radius: 4px;
}

.ProseMirror-yjs-cursor > span:before {
  content: attr(data-username);
}

.ProseMirror-yjs-cursor-up {
  display: inline !important;
}

.ProseMirror-yjs-cursor-user {
  display: none;
}

.ProseMirror-yjs-cursor-down {
  display: none;
}
